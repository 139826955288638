import BigNumber from 'bignumber.js'
import { ChainId } from '@ape.swap/sdk'
import { PoolConfig, PoolCategory } from '../types'
import tokens from './tokens'

const BANANA_PER_BLOCK = new BigNumber(10)

// Current BNB Sous ID is 240
// Current MATIC Sous ID is 1000

const pools: PoolConfig[] = [
  // MASTER APE V2 POOL
  {
    sousId: 0,
    tokenName: tokens.banana.symbol,
    stakingToken: tokens.banana,
    rewardToken: tokens.banana,
    contractAddress: {
      [ChainId.BSC]: '0x71354AC3c695dfB1d3f595AfA5D4364e9e06339B', // MasterApe
      [ChainId.BSC_TESTNET]: '',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    harvest: true,
    tokenPerBlock: `${BANANA_PER_BLOCK.toNumber() * 0.25}`,
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },

  // LEGACY POOL
  // Making sousId 999 for easy configuration
  {
    sousId: 999,
    tokenName: tokens.banana.symbol,
    stakingToken: tokens.banana,
    rewardToken: tokens.banana,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xbbC5e1cD3BA8ED639b00927115e5f0e0040aA613', // MasterApe
      [ChainId.BSC]: '0x5c8D727b265DBAfaba67E050f2f739cAeEB4A6F9',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    harvest: true,
    tokenPerBlock: `${BANANA_PER_BLOCK.toNumber() * 0.25}`,
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 237,
    tokenName: 'BNB',
    image: 'BNB.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.wbnb,
    contractAddress: {
      [ChainId.BSC]: '0xbc178ccbe5b6a4f04ed5624657ee4f0ecd47add4',
      [ChainId.BSC_TESTNET]: '',
    },
    poolCategory: PoolCategory.REAL_YIELD,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    harvest: true,
    tokenPerBlock: `0.000108319542710488`,
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },

  {
    sousId: 238,
    tokenName: 'BNB',
    image: 'BNB.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.banana,
    contractAddress: {
      [ChainId.BSC]: '0xc31c1027cedd0fabfc9d6118ff6423cf39f04523',
      [ChainId.BSC_TESTNET]: '0xc31c1027cedd0fabfc9d6118ff6423cf39f04523',
    },
    poolCategory: PoolCategory.REAL_YIELD,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    harvest: true,
    tokenPerBlock: `0.99`,
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },

  {
    sousId: 21,
    tokenName: 'BANANA',
    image: 'BANANA.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.banana,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x3996F919980778cb0fc1982087cAc01089e33fe7', // ApeRewardPool
      [ChainId.BSC]: '0x8F97B2E6559084CFaBA140e2AB4Da9aAF23FE7F8',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://apeswap.finance/apezone',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    harvest: true,
    tokenPerBlock: `0`,
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 72,
    tokenName: 'GUARD',
    image: 'GUARD.png',
    stakingToken: tokens.banana,
    rewardToken: tokens.guard,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x73d3d28cc018a89fe6518d7b5efbcfcf0695a0d9',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/',
    twitter: 'https://twitter.com/wolfdencrypto',
    harvest: true,
    tokenPerBlock: `4.050925925925925925`,
    sortOrder: 2,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 77,
    tokenName: 'CYT',
    image: 'CYT.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.cyt,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x64fc4bf63c4315156f56b953a42bf2459b227a68',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://dragonary.com/',
    twitter: 'https://twitter.com/DragonaryGame',
    harvest: true,
    tokenPerBlock: `13.503086419753086419`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 78,
    tokenName: 'HOTCROSS',
    image: 'HOTCROSS.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.hotcross,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x03f344ceb868841ffa262503fe1cf21b9cd5d7a8',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://hotcross.com/ ',
    twitter: 'https://twitter.com/hotcrosscom',
    harvest: true,
    tokenPerBlock: `0.54976851851`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 101,
    tokenName: 'DEP',
    image: 'DEP.png',
    stakingToken: tokens.banana,
    rewardToken: tokens.dep,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xdbb5df464f245d3b62b5fea995580f837f87fc12',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://dea.sg/',
    twitter: 'https://twitter.com/PlayMining_SG',
    harvest: true,
    tokenPerBlock: `14.081790123456790123`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 110,
    tokenName: 'SX',
    image: 'SX.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.sx,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xc6215d86a4bb470667d54c316624a3c45504bc31',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.sx.technology/',
    twitter: 'https://twitter.com/SX_Network',
    harvest: true,
    tokenPerBlock: `0.263310185185185185`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 114,
    tokenName: 'NFTY',
    image: 'NFTY.png',
    stakingToken: tokens.gnana,
    rewardToken: tokens.nfty,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x946a0e7b410cfc4e225a3e9f09a93259747b0903',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://nftynetwork.io/',
    twitter: 'https://twitter.com/NFTYLab',
    harvest: true,
    tokenPerBlock: `6.944444444444444444`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 121,
    tokenName: 'THG',
    image: 'THG.png',
    stakingToken: tokens.banana,
    rewardToken: tokens.thg,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xcf44a7c1d8277b27c47195f0907a5b93e63d3dd0',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://thetanarena.com/',
    twitter: 'https://twitter.com/ThetanArena',
    harvest: true,
    tokenPerBlock: `0.025462962962962962`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 122,
    tokenName: 'WSG',
    image: 'WSG.png',
    stakingToken: tokens.banana,
    rewardToken: tokens.wsg,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xde99115c3f8ee876b5520779665088f70f647a6d',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://wsg.gg/',
    twitter: 'https://twitter.com/WSGToken',
    harvest: true,
    tokenPerBlock: `570041.232638888888888888`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 129,
    tokenName: 'TOWER',
    image: 'TOWER.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.tower,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x07f874ad2b3546164a33f4b6c27494ac9c1d3323',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.crazydefenseheroes.com/',
    twitter: 'https://twitter.com/TowerToken',
    harvest: true,
    tokenPerBlock: `1.11158521412037037`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 133,
    tokenName: 'GAN',
    image: 'GAN.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.gan,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xea5b77b22bd939b8196587d2bb9d8b5e0e497b74',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://galacticarena.io/',
    twitter: 'https://twitter.com/GANFTverseBSC',
    harvest: true,
    tokenPerBlock: `9.645061728395061728`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 135,
    tokenName: 'iBFR',
    image: 'iBFR.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.ibfr,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x004D7f0661fF6e1420fE311Bca57C1D89C9751BA',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://www.buffer.finance/',
    twitter: 'https://twitter.com/Buffer_Finance',
    harvest: true,
    tokenPerBlock: `0.342086298263888888`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 139,
    tokenName: 'FXS',
    image: 'FXS.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.fxs,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x565478B15dDABCDAf8B42528a0bD6a1c04ce070b',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://frax.finance/',
    twitter: 'https://twitter.com/fraxfinance',
    harvest: true,
    tokenPerBlock: `0.005772569444444444`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 141,
    tokenName: 'QUIDD',
    image: 'QUIDD.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.quidd,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x7de443badcfcf49417864f70fe7734b8144082f1',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://market.onquidd.com/',
    twitter: 'https://twitter.com/quidd',
    harvest: true,
    tokenPerBlock: `0.047315827546296296`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 148,
    tokenName: 'GMEE',
    image: 'GMEE.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.gmee,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x61713875F0759803DD1C7e2a83ACd11265Ff80BB',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://www.gamee.com/',
    twitter: 'https://twitter.com/GAMEEToken',
    harvest: true,
    tokenPerBlock: `0.276112654320987654`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 150,
    tokenName: 'rASKO',
    image: 'rASKO.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.rasko,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xC4527Fb81764E632509234cE78F5C91cc86C7162',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://asko.finance/rasko',
    twitter: 'https://twitter.com/asko_official',
    harvest: true,
    tokenPerBlock: `4.675632804232803902`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 154,
    tokenName: 'LOA',
    image: 'LOA.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.loa,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x45d94C84cd5d150F1D6037f35f4dcaA6e1369ae8',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://www.leagueofancients.com/',
    twitter: 'https://twitter.com/LOAOfficialMOBA/',
    harvest: true,
    tokenPerBlock: `0.061574074074074074`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 155,
    tokenName: 'ADS',
    image: 'ADS.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.ads,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x66a17DAcE2d77f036422BDD50F3A20579613f5C0',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://adshares.net/',
    twitter: 'https://twitter.com/adsharesNet',
    harvest: true,
    tokenPerBlock: `0.00902777777`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 11,
  },
  {
    sousId: 156,
    tokenName: 'KAI',
    image: 'KAI.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.kai,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x3b15B487902Bb10Cba34c489Cb35AC342F73Cff2',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://kardiachain.io/',
    twitter: 'https://twitter.com/KardiaChain',
    harvest: true,
    tokenPerBlock: `0.82740162037037037`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 157,
    tokenName: 'DOSE',
    image: 'DOSE.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.dose,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xd0378c1b37D530a00E91764A7a41EfEB3d6A5fbC',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://dosetoken.com/',
    twitter: 'https://twitter.com/DOSEToken',
    harvest: true,
    tokenPerBlock: `0.299904899691358024`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 162,
    tokenName: 'GMR',
    image: 'GMR2.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.gmr,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x8966eda482ca1559cfea937da58f217fd1f2a468',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://gmr.center/',
    twitter: 'https://twitter.com/GMRCenter',
    harvest: true,
    tokenPerBlock: `0.30365296803652968`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 166,
    tokenName: 'ZIG',
    image: 'ZIG.png',
    stakingToken: tokens.gnana,
    rewardToken: tokens.zig,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xEbd0Cd94DC2BE20575EcdC0370a7d9A2c1AeD40c',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://zignaly.com/',
    twitter: 'https://twitter.com/zignaly',
    harvest: true,
    tokenPerBlock: `0.25076929012345679`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 172,
    tokenName: 'LMT',
    image: 'LMT.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.lmt,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x9f3Fa4d728F26C9bb53a0641C51A5657544E257A',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://lympo.io/lmt/',
    twitter: 'https://twitter.com/Lympo_io',
    harvest: true,
    tokenPerBlock: `0.395784143518518518`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 177,
    tokenName: 'NFTY',
    image: 'NFTY2.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.nfty2,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x0F555e92e9470d064F8A6D0b97a2E3F7d26A222d',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://nftytoken.io/ ',
    twitter: 'https://twitter.com/NFTYtoken',
    harvest: true,
    tokenPerBlock: `0.073888888888888888`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 178,
    tokenName: 'LUNR',
    image: 'LUNR.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.lunr,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xFdE855148B160F203761569A9c0A8a1aA7d565B3',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://lunarcrush.com/',
    twitter: 'https://twitter.com/lunarcrush',
    harvest: true,
    tokenPerBlock: `0.0549`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 4,
  },
  {
    sousId: 187,
    tokenName: 'HOTCROSS',
    image: 'HOTCROSS.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.hotcross,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x448B5c60baa2E7D85334706494845b71F07151EE',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://hotcross.com/',
    twitter: 'https://twitter.com/hotcrosscom',
    harvest: true,
    tokenPerBlock: `0.222087191358024691`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 190,
    tokenName: 'ANML',
    image: 'ANML.png',
    stakingToken: tokens.gnana,
    rewardToken: tokens.anml,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x363f3b8471223f66a09f65f1253cd41be916fb51',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://animalconcerts.com/',
    twitter: 'https://twitter.com/animalconcerts',
    harvest: true,
    tokenPerBlock: `8.573388117283950617`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 193,
    tokenName: 'BOUNTIE',
    image: 'BOUNTIE.png',
    stakingToken: tokens.banana,
    rewardToken: tokens.bountie,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x59A4B153D080E3066236e57f6Bd66Ad241eb0269',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://bountiehunter.io/',
    twitter: 'https://twitter.com/bountie_hunter',
    harvest: true,
    tokenPerBlock: `0.990789351851851851`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 196,
    tokenName: 'KTE',
    image: 'KTE.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.kte,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x3ece92fa22766d4a514cddace768d085e75e59cf',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://kyte.one',
    twitter: 'https://twitter.com/kyteone',
    harvest: true,
    tokenPerBlock: `0.159142939814814814`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 202,
    tokenName: 'Froyo',
    image: 'Froyo.png',
    stakingToken: tokens.gnana,
    rewardToken: tokens.froyo,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x59258b42648486C3A3DF24a526996f82F526a9a3',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://froyo.games/home',
    twitter: 'https://twitter.com/realfroyogames',
    harvest: true,
    tokenPerBlock: `0.558215133101851851`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 203,
    tokenName: 'PRIMATE',
    image: 'PRIMATE.png',
    stakingToken: tokens.gnana,
    rewardToken: tokens.primate,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xc5EA4c201F9D9E052e197811a7bbDd56680F8Fcd',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://www.benjibananas.com/',
    twitter: 'https://twitter.com/BenjiBananas',
    harvest: true,
    tokenPerBlock: `0.439506172839506172`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 207,
    tokenName: 'ROOBEE',
    image: 'ROOBEE.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.roobee,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x3490F8a48B421647E853DF8Ec82610Ac60bBD727',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://roobee.io/',
    twitter: 'https://twitter.com/Roobee_invest',
    audit: 'https://github.com/mixbytes/audits_public/blob/master/Roobee/README.md',
    harvest: true,
    tokenPerBlock: `10.7421875`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 208,
    tokenName: 'VTG',
    image: 'VTG.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.vtg,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x78A81CC8a83DDe46B352Ab7e478C1Ebe087CD5b6',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://olympicgamesjam.nwayplay.com/',
    twitter: 'https://twitter.com/OlympicGamesJam',
    harvest: true,
    tokenPerBlock: `3.858024691358024691`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 209,
    tokenName: 'TLOS',
    image: 'TLOS.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.tlos,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x03BC491529B6443b263398A366E4424f6F669224',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    harvest: true,
    tokenPerBlock: `0.028645833330000000`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 212,
    tokenName: 'RADAR',
    image: 'RADAR.png',
    stakingToken: tokens.gnana,
    rewardToken: tokens.radar,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x6bE84367AC627617c3d4143fbBD288cC5e9fb7c3',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://dappradar.com/',
    twitter: 'https://twitter.com/dappradar',
    harvest: true,
    tokenPerBlock: `0.936383680555555555`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 215,
    tokenName: 'HEC',
    image: 'HEC.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.hec,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x973975F0f5A265902e19088B7B106c9a688DE697',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://hector.network/',
    twitter: 'https://twitter.com/Hector_Network',
    harvest: true,
    tokenPerBlock: `0.001345486`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 9,
  },
  {
    sousId: 216,
    tokenName: 'TOR',
    image: 'TOR.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.tor,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x09A536D113e7B298598c7902F6435F9b50b20D6C',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://hector.network/',
    twitter: 'https://twitter.com/Hector_Network',
    audit: 'https://www.certik.com/projects/tor',
    harvest: true,
    tokenPerBlock: `0.019097222222222222`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 217,
    tokenName: 'MHUNT',
    image: 'MHUNT.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.mhunt,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x627AE8d0c943B19e80B3338b45a9E4Ff81Cb8A7E',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://metashooter.gg/',
    twitter: 'https://twitter.com/MetaShooter_gg',
    audit:
      'https://github.com/sokenteam/smart_contract_audits/blob/main/MetaShooter%20NFT%20Standard%20Smart%20Contract%20Audit.pdf',
    harvest: true,
    tokenPerBlock: `0.381944444444444444`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 219,
    tokenName: 'BAPE',
    image: 'BAPE.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.bape,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0xD04249B0899403BafD2AE771084E3Cb3899f1F55',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://apemove.io/',
    twitter: 'https://twitter.com/APEmoveApp',
    harvest: true,
    tokenPerBlock: `2.673611111111111111`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 221,
    tokenName: 'PSTN',
    image: 'PSTN.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.pstn,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x9510dce1b7765B0881bf0C37E205dC1A43e3a7aA',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://piston-token.com/swap',
    twitter: 'https://twitter.com/PistonToken',
    audit: 'https://solidity.finance/audits/PistonToken/',
    harvest: true,
    tokenPerBlock: `0.0023283179`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 223,
    tokenName: 'CHRP',
    image: 'CHRP.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.chrp,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x0eE018eF954ca14A38bc2Aed702fB6CFA6ae6c69',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://chirpley.ai',
    twitter: 'https://twitter.com/chirpley',
    audit: 'https://stack.chirpley.nl/s/241c8gWBtVHnuZO1',
    harvest: true,
    tokenPerBlock: `0.900205632716049382`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
    zapable: true,
  },
  {
    sousId: 226,
    tokenName: '3AIR',
    image: '3AIR.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.threeair,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x58e3149f5d5d26c7319e88dbb7ad6f2951610715',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://3air.io/',
    twitter: 'https://twitter.com/3aircrypto',
    audit: 'https://github.com/JorgeRodriguezsec/CTDsec/blob/main/Audits/Cybersecurity_Audit_CTDSEC_3air.pdf',
    harvest: true,
    tokenPerBlock: `0.331579861111111111`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 228,
    tokenName: 'FRUIT',
    image: 'FRUIT.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.fruit,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x7D36b1c407554C81EeA30866c24c7B046B78d7E2',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://cryptofruits.com/index.php',
    twitter: 'https://twitter.com/CryptoFruitsFi',
    audit: 'https://hashex.org/audits/cryptofruits/',
    harvest: true,
    tokenPerBlock: `1.008603587962962962`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 230,
    tokenName: 'MONSTA',
    image: 'MONSTA.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.monsta,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x7b975Fa251F8b6eCe94f47CCbAEF71CE60db6704',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://cake.monster/',
    twitter: 'https://twitter.com/thecakemnstr',
    audit: 'https://solidity.finance/audits/CakeMonster/',
    harvest: true,
    tokenPerBlock: `14.895833333333333333`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 231,
    tokenName: 'ZBC',
    image: 'ZBC.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.zbc,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x7b053D119b5058b2930cee9F47aa9028b5c3Ae1a',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://zebec.io/',
    twitter: 'https://twitter.com/Zebec_HQ',
    audit: 'https://github.com/Zebec-protocol/zebec-program-v2/tree/master/audit',
    harvest: true,
    tokenPerBlock: `1.193576388`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 9,
  },
  {
    sousId: 235,
    tokenName: 'CVL',
    image: 'CVL.svg',
    stakingToken: tokens.gnana,
    rewardToken: tokens.cvl,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0xD0F8137d15c0169cD464CabC2655fE237C8C96Ca',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://cvl.network',
    twitter: 'https://twitter.com/cvl_network',
    audit: 'https://github.com/xdao-app/xdao-contracts/blob/master/audits/hacken/hacken.pdf',
    harvest: true,
    tokenPerBlock: `1.909722222222222222`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  //
  // JUNGLE FARMS
  //
  {
    sousId: 214,
    tokenName: 'PXP',
    image: 'PXP.png',
    stakingToken: tokens.pxpBnb,
    rewardToken: tokens.pxp,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xBE36303aeCc8F1A71fa1076349117BE2fb94f7cD',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://piratexpirate.io/',
    twitter: 'https://twitter.com/PXPNFTsGame',
    harvest: true,
    tokenPerBlock: `15.659722222222222222`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 225,
    tokenName: 'SWT',
    image: 'SWT.svg',
    stakingToken: tokens.swtBnb,
    rewardToken: tokens.swt,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x6d986e76745fa4f99bad44dd2792351bf2d0800f',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://sweetpad.io/',
    twitter: 'https://twitter.com/SweetPad_',
    harvest: true,
    tokenPerBlock: '0.07398535704',
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 211,
    tokenName: 'GENv3',
    image: 'GENV3.svg',
    stakingToken: tokens.genv3Bnb,
    rewardToken: tokens.genv3,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xc3a5dbb5121772628956b9ce709ec1dda6a94b4d',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://www.generic.money/',
    twitter: 'https://twitter.com/thegenericcoin',
    harvest: true,
    tokenPerBlock: `19097.222222222222222222`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 210,
    tokenName: 'BONES',
    image: 'BONES.png',
    stakingToken: tokens.bonesBnb,
    rewardToken: tokens.bones,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x84291c0e6b19cc833afc491561aacfbdd6885d63',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://moonshots.farm/',
    twitter: 'https://twitter.com/MoonshotsFarm',
    harvest: true,
    tokenPerBlock: `0.224606095679012345`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 206,
    tokenName: 'LGX-BUSD',
    image: 'LGX.svg',
    stakingToken: tokens.lgxBusd,
    rewardToken: tokens.lgx,
    lpTokens: {
      token: tokens.lgx,
      quoteToken: tokens.busd,
    },
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0xc81af2222ac6ec0f3ec08b875df25326b40e7a76',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://solo.to/legionnetwork',
    harvest: true,
    tokenPerBlock: '0.507454668209876543',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: true,
    isEarnTokenLp: false,
  },
  {
    sousId: 204,
    tokenName: 'LC-BNB',
    image: 'LC.svg',
    stakingToken: tokens.lcBnb,
    rewardToken: tokens.lc,
    lpTokens: {
      token: tokens.lc,
      quoteToken: tokens.wbnb,
    },
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x1e7133e05d1b4bdab44cb5503fa4a801000c963a',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://www.luckychip.io/',
    harvest: true,
    tokenPerBlock: '2.864583333333333333',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: true,
    isEarnTokenLp: false,
  },
  {
    sousId: 201,
    tokenName: 'COC-BNB',
    image: 'COC.svg',
    stakingToken: tokens.cocBnb,
    rewardToken: tokens.coc,
    lpTokens: {
      token: tokens.coc,
      quoteToken: tokens.wbnb,
    },
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0xf4195c4ddb10db3df27816bb70dc342d861a7561',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://www.coinofchampions.com/',
    harvest: true,
    tokenPerBlock: '62715.1860896991',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: true,
    isEarnTokenLp: false,
  },
  {
    sousId: 186,
    tokenName: 'NFT11-BUSD',
    image: 'NFT11.png',
    stakingToken: tokens.nft11Busd,
    rewardToken: tokens.nft11,
    lpTokens: {
      token: tokens.nft11,
      quoteToken: tokens.busd,
    },
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0x420B9baD20d43D561377615325E60d5B7CdB1B35',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://nft11.io/',
    harvest: true,
    tokenPerBlock: '0.059799768518518518',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: true,
    isEarnTokenLp: false,
  },
  {
    sousId: 189,
    tokenName: 'RUBY-BNB',
    image: 'RUBY.svg',
    stakingToken: tokens.rubyBnb,
    rewardToken: tokens.ruby,
    lpTokens: {
      token: tokens.ruby,
      quoteToken: tokens.wbnb,
    },
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0xbc3f5571fc3338d0b9216a2c5c2e6f338531e3f8',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://www.rubyplaynet.com/',
    harvest: true,
    tokenPerBlock: '2.864583333333',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 12,
    lpStaking: true,
    isEarnTokenLp: false,
  },
  {
    sousId: 191,
    tokenName: 'DRF-BUSD',
    image: 'DRF.svg',
    stakingToken: tokens.drfBusd,
    rewardToken: tokens.drf,
    lpTokens: {
      token: tokens.drf,
      quoteToken: tokens.busd,
    },
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
      [ChainId.BSC]: '0xc8519d981a99c9a56364895c6d37eea686d70540',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://derify.finance/',
    harvest: true,
    tokenPerBlock: '0.06875',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    lpStaking: true,
    isEarnTokenLp: false,
  },
  {
    sousId: 220,
    tokenName: 'BNBx-BNB',
    image: 'BNBX.svg',
    stakingToken: tokens.bnbxBnb,
    rewardToken: tokens.sd,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x20589c5cc1907023beca7e4e9a2325e1ef5f30ef',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://staderlabs.com/',
    twitter: 'https://twitter.com/stader_bnb',
    harvest: true,
    tokenPerBlock: `0.00455860716`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 222,
    tokenName: 'BRZ-BUSD',
    image: 'BRZ.svg',
    stakingToken: tokens.brzBusd,
    rewardToken: tokens.brz,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0xdffcb3f3a64b8c7d722b33d098349417136585f8',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://www.transfero.com/transfero-crypto?lang=en',
    twitter: 'https://twitter.com/transferogroup',
    harvest: true,
    tokenPerBlock: `0.0171`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 4,
  },
  {
    sousId: 227,
    tokenName: 'LEAP',
    image: 'LEAP.svg',
    stakingToken: tokens.leapBnb,
    rewardToken: tokens.leap,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x60d0926F7669d2F36845eAf672e57366c0c2bD7B',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://www.playleap.io/',
    twitter: 'https://twitter.com/Play_Leap',
    harvest: true,
    tokenPerBlock: `0.403084201388888888`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 232,
    tokenName: 'AVAN',
    image: 'AVAN.svg',
    stakingToken: tokens.avanBnb,
    rewardToken: tokens.avan,
    contractAddress: {
      97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      56: '0x822558FCE9D9F98cB59953F4841e5e43cEba5173',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://avanatoken.com/',
    twitter: 'https://twitter.com/AvanaProject',
    harvest: true,
    tokenPerBlock: `3.104730529`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 233,
    tokenName: 'WCFLT',
    image: 'WCFLT.svg',
    stakingToken: tokens.wcfltBnb,
    rewardToken: tokens.wcflt,
    contractAddress: {
      56: '0x0d665613d47C7eCC1B126bF56D5fa6373E3000b6',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://www.coinflect.com/',
    twitter: 'https://twitter.com/coinflect?lang=en',
    harvest: true,
    tokenPerBlock: `108.140734953703703703`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 234,
    tokenName: 'XTAL',
    image: 'XTAL.png',
    stakingToken: tokens.xtalBusd,
    rewardToken: tokens.xtal,
    contractAddress: {
      56: '0x0d665613d47C7eCC1B126bF56D5fa6373E3000b6',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'https://talesofxtalnia.com/',
    twitter: 'https://twitter.com/xtalnia',
    audit: 'https://hashex.org/audits/xtal-token/',
    harvest: true,
    tokenPerBlock: '0.09352199074074074',
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 236,
    tokenName: 'USDT',
    image: 'USDT.svg',
    stakingToken: tokens.lgcBnb,
    rewardToken: tokens.usdt,
    contractAddress: {
      56: '0x2005166d71090338a45399FF05BBE242AbF96c3E',
    },
    poolCategory: PoolCategory.JUNGLE,
    projectLink: 'http://livegreencoin.com/',
    twitter: 'https://twitter.com/LiveGreenCoin1',
    audit: 'https://www.certik.com/projects/livegreencoin',
    harvest: true,
    tokenPerBlock: '0.0011574074',
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },

  //
  // PUT ALL ADMIN POOLS UNDER HERE
  //
  {
    sousId: 240,
    tokenName: 'ABOND',
    image: 'ABOND.svg',
    stakingToken: tokens.apemin,
    rewardToken: tokens.abond,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x9671671678b407f474210482db3E8C295c071208',
      [ChainId.BSC]: '0x9671671678b407f474210482db3E8C295c071208',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://ape.bond/',
    harvest: true,
    tokenPerBlock: `0.01281262813`,
    sortOrder: 1,
    reflect: false,
    isFinished: false,
    tokenDecimals: 18,
    forAdmins: true,
  },
  {
    sousId: 239,
    tokenName: 'BANANA',
    image: 'BANANA.svg',
    stakingToken: tokens.apemin,
    rewardToken: tokens.banana,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0xbe57042d1e4e73e560cb32620b8a7e9787a4befb',
      [ChainId.BSC]: '0xbe57042d1e4e73e560cb32620b8a7e9787a4befb',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: `0.01645602949`,
    sortOrder: 1,
    reflect: false,
    isFinished: false,
    tokenDecimals: 18,
    forAdmins: true,
  },
  {
    sousId: 96,
    tokenName: 'BANANA',
    image: 'BANANA.svg',
    stakingToken: tokens.obie,
    rewardToken: tokens.banana,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xf1aFc0bfdf33BC74b9896612eAA38058cADfF8f0',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: `0`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
    forAdmins: true,
  },
  {
    sousId: 123,
    tokenName: 'CYT',
    image: 'CYT.svg',
    stakingToken: tokens.obie,
    rewardToken: tokens.cyt,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0xcd40fbe9eabc34faa59341c36c628e8867a22943',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://twitter.com/obie_Dobo/',
    twitter: 'https://twitter.com/DragonaryGame',
    harvest: true,
    tokenPerBlock: `0.11574074074074074`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
    forAdmins: true,
  },
  {
    sousId: 192,
    tokenName: 'ANML',
    image: 'ANML.png',
    stakingToken: tokens.obie,
    rewardToken: tokens.anml,
    contractAddress: {
      [ChainId.BSC_TESTNET]: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
      [ChainId.BSC]: '0x5a92DDA1338073065Ef142fD02e3Af2Ebfb07212',
    },
    poolCategory: PoolCategory.APEZONE,
    projectLink: 'https://animalconcerts.com/',
    twitter: 'https://twitter.com/animalconcerts',
    harvest: true,
    tokenPerBlock: `0.09645061728395061`,
    sortOrder: 1,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
    forAdmins: true,
  },

  /* Polygon Pools Starts Here */
]

export default pools
